<template>
  <div>
    <h4 class="card-label border-bottom mb-3">Menu Extra Types</h4>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              v-if="permission.is_add == 1"
              @click="addMenuExtraTypes"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
            >
              Add Menu Extra Type
            </b-button>
            <b-modal
              hide-footer
              id="modal-standard"
              :title="menu_extra_type_title"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                  label="Type Name"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                >
                  <b-form-input
                    id="name-input"
                    class="input-style"
                    v-model="extra_type"
                    required
                  ></b-form-input>
                </b-form-group>
                <div>
                  <b-form-group label="Is Compulsory">
                    <div class="d-flex align-items-center">
                      <b-form-radio
                        v-model="is_compulsory"
                        name="is_compulsory"
                        value="1"
                        >Yes
                      </b-form-radio>
                      <b-form-radio
                        v-model="is_compulsory"
                        name="is_compulsory"
                        value="0"
                        class="ml-3"
                        >No
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </div>
                <b-form-group label="Description" label-for="comment-input">
                  <b-form-textarea
                    id="description-input"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>
                <div class="m-t-15">
                  <b-button
                    type="button"
                    @click="handleOk"
                    class="mt-3 btn-orange pull-right"
                    :disabled="isFormValid"
                  >
                    Add
                  </b-button>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-sm-12 col-md-3">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-9">
        <div class="dataTables_filter text-md-right filter-dataTables">
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <div class="ml-2">
            <button class="btn print excel-btn" @click="excelExport()">
              Export to Excel
            </button>
            <button class="btn print" @click="csvExport(csvData)">
              Export to CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="menu_extra_types"
            :fields="menu_extra_type_fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(action)="menu_extra_types">
              <div class="position-relative">
                <div class="table-actions">
                  <a
                    v-if="permission.is_edit == 1"
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editModal(menu_extra_types)"
                    v-b-modal.modal-standard
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a
                    v-if="permission.is_deleted == 1"
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteExtraType(menu_extra_types)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import XLSX from "xlsx";
import helpers from "../../helpers";
import axios from "axios";

export default {
  name: "MenuExtraTypes",
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      loader: true,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      // sortBy: "title",
      sortDesc: true,
      DropDownMenu: false,
      uuid: "",
      menu_extra_types: [],
      menu_extra_type_fields: [
        { key: "extra_type", label: "Extra Type", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "action" }
      ],
      menu_extra_type_uuid: "",
      extra_type: "",
      description: "",
      is_compulsory: "",
      restaurantUuid: JSON.parse(localStorage.getItem("restaurantDetails"))
        .uuid,
      menu_extra_type_title: "",
      is_edit: false
    };
  },
  components: {},
  computed: {
    rows() {
      return this.menu_extra_types.length;
    },
    csvData() {
      return this.menu_extra_types.map(item => ({
        ...item
      }));
    },
    isFormValid() {
      return !(this.extra_type !== "" && this.is_compulsory !== "");
    }
  },
  methods: {
    addMenuExtraTypes() {
      this.extra_type = "";
      this.description = "";
      this.is_compulsory = "";
      this.menu_extra_type_title = "Add Menu Extra Type";
      this.is_edit = false;
    },
    resetModal() {},
    editModal(extra_type) {
      this.menu_extra_type_uuid = extra_type.item.uuid;
      this.extra_type = extra_type.item.extra_type;
      this.description = extra_type.item.description;
      this.is_compulsory = extra_type.item.is_compulsory;
      this.menu_extra_type_title = "Edit Menu Extra Type";
      this.is_edit = true;
    },
    handleOk() {
      this.loader = true;
      let obj = {
        extra_type: this.extra_type,
        is_compulsory: this.is_compulsory,
        description: this.description
      };
      this.is_edit ? this.updateMenuExtra(obj) : this.addMenuExtra(obj);
    },
    addMenuExtra(obj) {
      ApiService.post(
        this.$path + "/admin/menu/extra/type/restaurant/" + this.restaurantUuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          this.menu_extra_types.unshift(response.data.data);
          this.loader = false;
          this.$bvModal.hide("modal-standard");
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateMenuExtra(obj) {
      ApiService.patch(
        this.$path +
          "/admin/menu/extra/type/restaurant/" +
          this.restaurantUuid +
          "/" +
          this.menu_extra_type_uuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          let index = this.menu_extra_types.findIndex(
            x => x.uuid === response.data.data.uuid
          );
          if (index !== -1) {
            this.menu_extra_types[index].uuid = response.data.data.uuid;
            this.menu_extra_types[index].extra_type =
              response.data.data.extra_type;
            this.menu_extra_types[index].is_compulsory =
              response.data.data.is_compulsory;
            this.menu_extra_types[index].description =
              response.data.data.description;
          }
          this.loader = false;
          this.$bvModal.hide("modal-standard");
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getMenuExtraType() {
      this.loader = true;
      axios
        .get(
          this.$path +
            "/admin/menu/extra/type/restaurant/" +
            this.restaurantUuid
        )
        .then(response => {
          this.menu_extra_types = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    deleteExtraType(extra_type) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(
            this.$path +
              "/admin/menu/extra/type/restaurant/" +
              this.restaurantUuid +
              "/" +
              extra_type.item.uuid
          )
            .then(response => {
              helpers.successMessages(this, response.data.message);
              let index = this.menu_extra_types.findIndex(
                x => x.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.menu_extra_types.splice(index, 1);
              }
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "MenuExtraTypes.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.menu_extra_types);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "MenuExtraTypes.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.getMenuExtraType();
    this.totalRows = this.menu_extra_types.length;
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>

<style scoped></style>
